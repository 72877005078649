import '../styles/globals.sass'
import React, { FC } from 'react'
import { AppProps } from 'next/app'
import { wrapper } from '../redux/store'
import client from '../graphql/helpers/apollo'
import { ApolloProvider } from '@apollo/client'

const WrappedApp: FC<AppProps> = ({ Component, pageProps }) => {
    return (
        <ApolloProvider client={client}>
            <Component {...pageProps} />{' '}
        </ApolloProvider>
    )
}

export default wrapper.withRedux(WrappedApp)
