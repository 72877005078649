import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import modalsReducer from './modals'
import settingsStateReducer from './settingsState'

export const store = configureStore({
    reducer: {
        modals: modalsReducer,
        settingsState: settingsStateReducer
    }
})

const makeStore = () => store
export type AppStore = ReturnType<typeof makeStore>
export const wrapper = createWrapper<AppStore>(makeStore)
export type RootState = ReturnType<typeof store.getState>
