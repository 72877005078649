import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

type SettingsState = {
    name: string | undefined
    avatar: string | undefined
}

const initialState: SettingsState = { name: undefined, avatar: undefined }

const settingsStateSlice = createSlice({
    name: 'settingsState',
    initialState,
    reducers: {
        setName: (state, action) => ({ ...state, name: action.payload }),
        setAvatar: (state, action) => ({ ...state, avatar: action.payload })
    }
})

export const selectName = (state: RootState) => state.settingsState.name
export const selectAvatar = (state: RootState) => state.settingsState.avatar

export const { setName, setAvatar } = settingsStateSlice.actions

export default settingsStateSlice.reducer
