import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

type ModalsState = {
    writePostModalOpen: boolean
    unauthorizedModalOpen: boolean
    errorModalError: string | null
    chatToStart: number | null
}

const initialState: ModalsState = {
    writePostModalOpen: false,
    unauthorizedModalOpen: false,
    errorModalError: null,
    chatToStart: null
}

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setWritePostModalOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            writePostModalOpen: action.payload
        }),
        setUnauthorizedModalOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            unauthorizedModalOpen: action.payload
        }),
        setErrorModalError: (state, action: PayloadAction<string | null>) => ({
            ...state,
            errorModalError: action.payload
        }),
        setChatToStart: (state, action: PayloadAction<number | null>) => ({
            ...state,
            chatToStart: action.payload
        })
    }
})

export const selectWritePostModalOpen = (state: RootState) =>
    state.modals.writePostModalOpen

export const selectUnauthorizedModalOpen = (state: RootState) =>
    state.modals.unauthorizedModalOpen

export const selectErrorModalError = (state: RootState) =>
    state.modals.errorModalError

export const selectChatToStart = (state: RootState) => state.modals.chatToStart

export const {
    setWritePostModalOpen,
    setUnauthorizedModalOpen,
    setErrorModalError,
    setChatToStart
} = modalsSlice.actions

export default modalsSlice.reducer
