import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    split
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import isBrowser from '../../util/isBrowser'
import { createClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import serverLog from '../../util/log'
import { OperationDefinitionNode } from 'graphql/language'

const uri = process.env.GRAPHQL_URI
    ? process.env.GRAPHQL_URI
    : process.env.NEXT_PUBLIC_GRAPHQL_URI

if (!uri) throw new Error('No Graphql API url was provided.')

const httpLink = createHttpLink({
    uri,
    credentials: 'include'
})

const buildWsLink = () => {
    const parsedUrl = new URL(uri)

    if (uri.startsWith('https')) {
        parsedUrl.protocol = 'wss'
    } else {
        parsedUrl.protocol = 'ws'
    }

    return new GraphQLWsLink(
        createClient({
            url: parsedUrl.toString()
        })
    )
}

const buildSplitLink = () =>
    split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return (
                (definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription') ||
                definition.name?.value === 'SendMessage'
            )
        },
        buildWsLink(),
        httpLink
    )

const authLink = setContext((_, { headers }) => {
    let customHeaders: { [key: string]: any } = {}

    if (process.env.HASURA_ADMIN_SECRET) {
        customHeaders = {
            ...customHeaders,
            'X-Hasura-Admin-Secret': process.env.HASURA_ADMIN_SECRET
        }
    }

    if (process.env.NEXT_PUBLIC_LOCALHOST === 'true') {
        customHeaders = { ...customHeaders, 'X-Debug-Host': 'localhost' }
    }

    return {
        headers: {
            ...headers,
            ...customHeaders
        }
    }
})

const logLink = onError(({ graphQLErrors, networkError, operation }) => {
    const operationInfo = {
        name: operation.operationName,
        type: (operation.query.definitions[0] as OperationDefinitionNode)
            ?.operation
    }

    if (graphQLErrors)
        graphQLErrors.forEach(error => {
            serverLog(
                JSON.stringify({
                    operation: operationInfo,
                    variables: operation.variables,
                    error: {
                        code: error.extensions.code,
                        graph: error.extensions.graph,
                        message: error.message,
                        path: error.path
                    }
                }),
                'graphql',
                'error'
            ).then()
        })

    if (networkError)
        serverLog(
            JSON.stringify({
                operation: operationInfo,
                networkError
            }),
            'graphql',
            'error'
        ).then()
})

const defaultFetchPolicy = isBrowser ? 'network-only' : 'no-cache'

const client = new ApolloClient({
    link: authLink.concat(
        isBrowser ? buildSplitLink() : logLink.concat(httpLink)
    ),
    cache: new InMemoryCache(),
    connectToDevTools: isBrowser,
    ssrMode: true,
    credentials: 'include',
    defaultOptions: {
        query: {
            fetchPolicy: defaultFetchPolicy,
            errorPolicy: 'all'
        },
        mutate: {
            fetchPolicy: defaultFetchPolicy,
            errorPolicy: 'all'
        }
    }
})

export default client
