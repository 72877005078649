// frontend log is logs__frontend mutation
// {console="log"} |= ``
const serverLog = async (
    message: string,
    key: string = 'console',
    value: string = 'log'
) => {
    let headers = new Headers({
        'Content-Type': 'application/json'
    })

    const basicString = process.env.LOKI_BASIC_AUTH

    if (!basicString) throw new Error('Loki auth is missing in env')

    headers.set('Authorization', 'Basic ' + basicString)

    // node
    // headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));
    // browser
    // headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    const time = Date.now() + '000000'

    const stream = { [key]: value }

    const raw = JSON.stringify({
        streams: [
            {
                stream,
                values: [[time, message]]
            }
        ]
    })

    const hostString = process.env.LOKI_HOST

    if (!hostString) throw new Error('Loki host is missing in env')

    fetch(new URL(hostString + '/loki/api/v1/push'), {
        method: 'POST',
        headers,
        body: raw,
        redirect: 'follow'
    }).then() // 204
}
export default serverLog
